var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.inventory && _vm.inventory.length)?_c('div',{staticClass:"grow d-grid flex-column px-0 mx-0"},[_c('VRow',{staticClass:"shrink ma-0"},[_c('VCol',{attrs:{"cols":"3"}},[_c('VAutocomplete',{staticClass:"pt-4",staticStyle:{"z-index":"500"},attrs:{"placeholder":"Event Type","items":_vm.events,"dark":"","dense":""},model:{value:(_vm.event),callback:function ($$v) {_vm.event=$$v},expression:"event"}})],1),_c('VCol',{attrs:{"offset":"5","cols":"2"}},[_c('CMDatePicker',{attrs:{"identifier":"start","label":_vm.$t('grid_monitor.date_start'),"value":_vm.timeSelection.start || ''},on:{"updateDate":_vm.updateDate}})],1),_c('VCol',{attrs:{"cols":"2"}},[_c('CMDatePicker',{attrs:{"identifier":"end","min":_vm.timeSelection.start || '',"label":_vm.$t('grid_monitor.date_end'),"value":_vm.timeSelection.end || '',"left":"70"},on:{"updateDate":_vm.updateDate}})],1)],1),_c('VRow',{staticClass:"grow ma-0"},[_c('VCol',{staticClass:"ma-0 py-0",attrs:{"cols":"12"}},[_c('CMTable',{attrs:{"show-select":true,"page-size":12,"single-select":false,"origin":"measurements.inventory","loading":_vm.loading,"header-config":{
          TYPE: { advanced: 'numeric' },
          SAMPLING_TIME: { advanced: 'numeric' },
          INSTALLATION_DATE: { advanced: 'date' },
          STATION: { advanced: 'numeric' },
          TRANSFORMER: { advanced: 'numeric' },
          FEEDER: { advanced: 'numeric' },
          CGP: { advanced: 'numeric' },
          PHASE: { advanced: 'numeric' }
        },"custom-title":_vm.headerTranslations,"show-sync-map-btn":true},on:{"onToggleSelectAll":_vm.onToggleSelectAll,"onItemSelected":_vm.updateSelected}})],1)],1),_c('VRow',{staticClass:"shrink bottom-section"},[(_vm.feedback.visible)?_c('VCol',{staticClass:"p-feedback",attrs:{"cols":"12"}},[(_vm.feedback.visible)?_c('VAlert',{attrs:{"dense":"","type":_vm.feedback.type,"elevation":"5","transition":"slide-y-transition"}},[_vm._v(" "+_vm._s(_vm.feedback.message)+" ")]):_vm._e()],1):_vm._e(),_c('VCol',{attrs:{"cols":"2"}},[_c('VTextField',{attrs:{"label":"Aggregation","hint":"Enter a name","clearable":""},model:{value:(_vm.aggregation),callback:function ($$v) {_vm.aggregation=$$v},expression:"aggregation"}})],1),_c('VCol',{attrs:{"cols":"2"}},[_c('VAutocomplete',{staticClass:"pt-4",staticStyle:{"z-index":"500"},attrs:{"label":"Time Interval","disabled":!_vm.selected.length,"items":_vm.intervals,"dark":"","dense":""},model:{value:(_vm.interval),callback:function ($$v) {_vm.interval=$$v},expression:"interval"}})],1),_c('VCol',{staticClass:"d-flex",attrs:{"cols":"2"}},[_c('VBtn',{attrs:{"color":"primary black--text","disabled":_vm.setDisabledSendToPlot(_vm.event, _vm.interval)},on:{"click":_vm.sendToPlot}},[_vm._v(" "+_vm._s(_vm.loading ? _vm.$t('grid_monitor.sending') : _vm.$t('grid_monitor.send_to_plot'))+" ")])],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }